body { font-family: sans-serif; padding: 1em; }

.ais-SearchBox { margin: 1em 0; }

.ais-Pagination {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.ais-SearchBox { margin: 1em 0; }

.ais-InstantSearch {
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-CurrentRefinements-item {
  flex-wrap: wrap;
}

.ais-Hits-item {
  margin-bottom: 1em;
  padding: 0;
  border: none;
  box-shadow: none;
}

.ais-Hits-item img { margin-right: 1em; }

.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ais-HitsPerPage {
  display: inline-block;
}

.ais-HitsPerPage-select {
  width: auto;
  display: inline-block;
}

.ais-RefinementList-count {
  border: none;
  background-color: lightgray;
  padding: 1px 8px;
}

.ais-RefinementList {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 1em;
}

.hit-name { margin-bottom: 0.5em; }

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.map-pane {
  height: 100vh;
}

.mapboxgl-map {
  width: 600px;
  height: 600px;
  margin: 20px;
}

.ui.secondary.pointing.menu .active.item, .ui.secondary.pointing.menu .active.item:hover {
  border-color: #8a3122;
  border-width: 3px;
}

.card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.fiche-record-grid .row:nth-child(odd) {
  background-color: #f1f1f1;
}

.facets .ui.accordion .active.content  {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.facets .segment {
  width: 24%;
}

.facets .segment:first-child {
  margin-top: 1rem;
}

.facets .segment:last-child {
  margin-bottom: 1rem;
}

.facets .title {
  display: flex;
  align-items: center;
}

.left-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.facets .slider-refinements.ui.segment .ais-RefinementList {
  margin-top: 3em;
}

.slider-input {
  padding-left: 4px;
}

.facet-container.ui.dimmer > .content {
  color: unset;
  text-align: left;
  width: 100%;
}

.ui.labels.current-facets {
  margin-top: 1em;
}

.ui.segment.accordion > .title > h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ais-InstantSearch > .search-results-count {
  margin-bottom: 1em;
}

.ais-InstantSearch .pages {
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
}
